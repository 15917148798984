<template>
    <Section
        class="compare-cards-slice padding-0 bg-white"
        width="lg"
    >
        <div class="table-overflow width-full border-top shadow-element">
            <table>
                <thead>
                    <tr>
                        <td />
                        <td v-for="(card, index) in cards" :key="index">
                            <nuxt-link :to="$prismic.linkResolver( card )">
                                <PrismicImage
                                    :img="card.data.image"
                                    class="margin-bottom-2 margin-top-4"
                                    w="75"
                                    h="50"
                                />
                            </nuxt-link>

                            <p class="text-bold">{{ card.data.core_api.brand_data.title }}</p>
                        </td>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>{{ $translate('yearly_fee', 'Årskostnad') }}</td>
                        <td v-for="(card, index) in cards" :key="index" :class="{ highlight: checkLowest('yearlyCost', card) }" v-html="card.model.yearlyCostHtml" />
                    </tr>

                    <tr>
                        <td>{{ $translate('card_info_highest_credit', 'Högsta kredit') }}</td>
                        <td v-for="(card, index) in cards" :key="index" :class="{ highlight: checkHighest('maxCredit', card) }">{{ card.model.getMaxCreditString( translate( 'bd_unlimited', 'Obegränsad' ) ) }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('card_info_interest', 'Ränta') }}</td>
                        <td v-for="(card, index) in cards" :key="index" :class="{ highlight: checkLowest('minInterestPossible', card) }">{{ card.model.interestString }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('card_info_effective_interest', 'Effektiv ränta') }}</td>
                        <td v-for="(card, index) in cards" :key="index" :class="{ highlight: checkLowest('minEffectiveInterestPossible', card) }">{{ card.model.effectiveInterestString }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('card_info_interest_free', 'Räntefritt') }}</td>
                        <td v-for="(card, index) in cards" :key="index" :class="{ highlight: checkHighest('interestFreeDays', card) }">{{ card.model.getInterestFreeDaysString( translate('bd_days', '{days} dagar')) }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('cr_concierge_service', 'Concierge Service') }}</td>
                        <td v-for="(card, index) in cards" :key="index">{{ formatBoolean(card.model.hasConciergeService) }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('card_info_card_type', 'Korttyp') }}</td>
                        <td v-for="(card, index) in cards" :key="index" class="text-capitalize">{{ card.model.cardType }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('compare_age_limit', 'Åldersgräns') }}</td>
                        <td v-for="(card, index) in cards" :key="index" :class="{ highlight: checkLowest('minAge', card) }">{{ card.model.minAge }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('compare_income_requirements', 'Inkomstkrav') }}</td>
                        <td v-for="(card, index) in cards" :key="index">{{ formatBoolean(card.model.acceptsNoIncome) }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('card_info_currency_surcharge', 'Valutapåslag i utlandet') }}</td>
                        <td v-for="(card, index) in cards" :key="index">{{ card.model.getCurrencyExchangeFeeString( translate( 'bd_free', 'Gratis' ), translate( 'bd_currency_surcharge', '{percentage} (min {amount})", "{percentage} + {amount}")') ) }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('card_info_withdrawal_fee', 'Uttagsavgift') }}</td>
                        <td v-for="(card, index) in cards" :key="index">{{ card.model.getWithdrawalFeeString( translate( 'bd_free', 'Gratis' ), translate( 'bd_withdrawal_fee', '{percentage} (min {amount})') ) }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('compare_min_amount_to_pay', 'Minsta belopp att betala') }}</td>
                        <td v-for="(card, index) in cards" :key="index">{{ card.model.getInvoiceMinToPayString( translate( 'bd_invoice_min', '{percentage} (min {amount})')) }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('compare_payment_remarks', 'Betalningsanmärkningar') }}</td>
                        <td v-for="(card, index) in cards" :key="index">{{ formatBoolean(card.model.acceptsPaymentRemarks) }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('card_info_avi_fee', 'Aviavgift') }}</td>
                        <td v-for="(card, index) in cards" :key="index">{{ card.model.getAviFeeString( translate( 'bd_avi_fee', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)' ) ) }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('card_info_late_fee', 'Förseningsavgift') }}</td>
                        <td v-for="(card, index) in cards" :key="index" :class="{ highlight: checkLowest('latePaymentFee', card) }">{{ card.model.latePaymentFeeString }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('compare_default_interest', 'Dröjsmålsränta') }}</td>
                        <td v-for="(card, index) in cards" :key="index" :class="{ highlight: checkLowest('penaltyInterest', card) }">{{ card.model.penaltyInterestString }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('compare_overdraft_fee', 'Övertrasseringsavgift') }}</td>
                        <td v-for="(card, index) in cards" :key="index" :class="{ highlight: checkLowest('overdraftFee', card) }">{{ card.model.overdraftFeeString }}</td>
                    </tr>

                    <tr>
                        <td>{{ $translate('compare_review', 'Recension') }}</td>
                        <td v-for="(card, index) in cards" :key="index">
                            <nuxt-link :to="$prismic.linkResolver(card)" class="green">{{ $translate('button_apply', 'Recension') }}</nuxt-link>
                        </td>
                    </tr>

                    <tr>
                        <td />
                        <td v-for="(card, index) in cards" :key="index" class="padding-y-4">
                            <div class="flex">
                                <ButtonTarget
                                    :link="card"
                                    placement="comparison"
                                    :text="$translate('button_apply', 'Till ansökan')"
                                    background="orange"
                                    size="sm"
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Section>
</template>

<script>
import AddCard from '@/components/AddCardToCompare';
export default {    
    components: { AddCard },

    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            cards: [],
        };
    },
    computed: {
        lowest() {
            const lowest = {
                minInterestPossible: undefined,
                minEffectiveInterestPossible: undefined,
                minAge: undefined,
                overdraftFee: undefined,
                latePaymentFee: undefined,
                yearlyCost: undefined,
                penaltyInterest: undefined
            };
            const lowCheckers = Object.keys(lowest);
            let cardCategoryValues = [];

            lowCheckers.forEach(category => {
                this.cards.forEach(card => {
                    if (category === 'yearlyCost' && card.model.hasFirstYearOffer) {
                        cardCategoryValues.push(card.model.firstYearCost);
                    }
                    else {
                        cardCategoryValues.push(card.model[category]);
                    }
                });
                lowest[category] = Math.min(...cardCategoryValues);
                cardCategoryValues = [];
            });
            return lowest;
        },
        highest () {
            const highest = {
                interestFreeDays: undefined,
                maxCredit: undefined
            };

            let cardCategoryValues = [];
            const highCheckers = Object.keys(highest);

            highCheckers.forEach(category => {
                this.cards.forEach(card => {
                    cardCategoryValues.push(card.model[category]);
                });
                highest[category] = Math.max(...cardCategoryValues);
                cardCategoryValues = [];
            });
            return highest;
        },
    },
    mounted () {
        this.setCards();
    },
    methods: {
        cardModel (card) {
            return new this.$models.CreditCard(card, this.$store);
        },

        formatBoolean (bool) {
            if (bool) {
                return this.$translate('yes', 'Ja');
            } 
            return this.$translate('no', 'Nej');
        },

        checkLowest (key, card) {
            if (key === 'yearlyCost' && card.model.hasFirstYearOffer) {
                return card.model.firstYearCost <= this.lowest[key];
            }
            return card.model[key] <= this.lowest[key];
        },
        checkHighest (key, card) {
            return card.model[key] >= this.highest[key];
        },

        setCards() {
            // this.cards = []

            // NOTE: For testing
            // let fakeArr = [ 'YGycrhMAACEAfuri', 'YHP1IxMAACMAoBZ2', 'YIAfcxAAACYAF9TU' ]
            // for(let i = 0; i < fakeArr.length; i++) {
            //     let id = fakeArr[i]
            //     this.cards.push(this.$store.state.cards[id])
            //     cardIds.push(id)
            //     let idString = Object.keys(cardIds).map(function(key) {
            //         return cardIds[key]
            //     }).join('&');
            // }


            this.$store.commit('CLEAR_CARDS_TO_COMPARE');

            const cards = this.$route.query.card;

            if (Array.isArray(cards)) {
                for (let i = 0; i < cards.length; i++) {
                    const card = Object.assign({}, this.$store.state.cards[cards[i]]);
                    card.model = new this.$models.CreditCard(card);
                    this.cards.push(card);
                }
            }

            else {
                const card = Object.assign({}, this.$store.state.cards[cards]);
                card.model = new this.$models.CreditCard(card);
                this.cards.push(card);
            }
        },

        translate (key, fallback) {
            return this.$translate(key, fallback);
        }
    },
};
</script>
