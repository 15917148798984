<template>
    <div class="add-card-to-compare">
        <i class="fas fa-plus" @click="show = true" />

        <div v-if="show" class="add-card-to-compare-open padding-4 bg-white bxs text-left">
            <div class="width-full flex flex-space">
                <div class="flex-1 flex flex-x-start">
                    <i class="far fa-credit-card margin-left-0 margin-right-2" />
                    <h5>{{ $translate('add_card_to_compare', 'Lägg till kort att jämföra') }}</h5>
                </div>
                <i class="fas fa-times" @click="show = false, query = ''" />
            </div>

            <input
                ref="search"
                v-model="query"
                type="text"
                placeholder="Sök efter kort"
                @keydown.esc="show = false, query = ''"
            >

            <ul v-if="query.length > 0">
                <li
                    v-for="(card, index) in filteredList"
                    :key="index"
                    class="flex flex-x-start flex-keep"
                    @click="addCard(card.id)"
                >
                    <PrismicImage :img="card.data.image" h="20" w="30" />
                    <span class="flex-1 margin-left-2">{{ card.data.title }}</span>
                </li>
            </ul>
        </div>

        <div
            v-if="show"
            class="add-card-to-compare-overlay"
            @click="show = false, query = ''"
        />
    </div>
</template>

<script>
import { includes } from 'lodash';
export default {
    data () {
        return {
            show: false,
            filteredList: [],
            query: ''
        };
    },
    watch: {
        show() {
            if (this.show) {
                let self = this;
                this.$nextTick(() => {
                    self.$refs.search.focus();
                });
            }
        },
        query() {
            this.filteredList = [];
            Object.keys(this.$store.state.cards).forEach(key => {
                let title = this.$store.state.cards[key].data.title;
                let titleFormatted = title.toLowerCase();

                if (titleFormatted.includes(this.query.toLowerCase())) {
                    this.filteredList.push(this.$store.state.cards[key]); 
                }
            });
        }
    },
    methods: {
        addCard (id) {
            if (!this.cardIsAdded(id)) {
                this.$store.commit('ADD_CARD_TO_COMPARE', id);
                this.show = false;
                this.query = '';
            }
        },

        cardIsAdded (id) {
            return includes(this.$store.state.cardsToCompare, id);
        }
    }
};
</script>
