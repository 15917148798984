<template>
    <Section
        class="list-cards"
        width="md"
        :class="'bg-' + data.primary.background"
    >
        <h2 v-if="$helpers.filterEmptyElements(data.primary.h2)" class="section-header green m-center">{{ $prismic.asText(data.primary.h2) }}</h2>
        <div v-if="$helpers.filterEmptyElements(data.primary.teaser)" class="section-header width-full" v-html="$prismic.asHtml(data.primary.teaser)" />

        <div class="container">
            <Card
                v-for="(card, index) in cards"
                :key="index"
                :card="card"
                placement="list"
                :position="index + 1"
            />
        </div>
        <trust-us v-if="!data.primary.disable_trust_us" />
    </Section>
</template>

<script>
import Card from '@/components/cards/Card.vue';
import TrustUs from '@/components/TrustUs.vue';
export default {
    components: { Card, TrustUs },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        cards () {
            let sortable = Object.values(this.$store.state.cards);
            return sortable.sort(function(a, b) {
                if (a.data.title < b.data.title) {
                    return -1;
                }
                if (a.data.title > b.data.title) {
                    return 1;
                }
                return 0;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat( 1, 1fr );
}
</style>
